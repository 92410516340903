<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-layout class="patient-list" column>
    <v-card class="rounded-xl overflow-hidden" :disabled="disableWhileLoading">
      <v-toolbar class="primary" tag="div" flat dark tile>
        <div class="text-h6">{{ getpatientN + "s" }} Relationship</div>
        <v-spacer />
        <v-dialog
          persistent
          v-model="transferDialog"
          transition="dialog-top-transition"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-show="selecteds" color="primary" v-bind="attrs" v-on="on">
              <v-icon left>mdi-transit-transfer</v-icon>Relationship</v-btn
            >
          </template>
          <template>
            <v-card>
              <v-toolbar color="primary" dark>Select Employee</v-toolbar>
              <v-card-text>
                <div class="text-h2 pt-4 px-4 d-flex">
                  <v-select
                    v-model="employeeUuid"
                    label="Employees"
                    :items="doctorstotransfer"
                    prepend-inner-icon="mdi-doctor"
                    item-text="fullname"
                    item-value="uuid"
                    :loading="loading"
                    hide-details
                    clearable
                    outlined
                    dense
                  />
                  <v-select
                    class="ml-1"
                    :disabled="employeeUuid == '' || employeeUuid == null"
                    v-model="ocupationId"
                    label="Occupations"
                    :items="ocupations"
                    item-text="name"
                    item-value="id"
                    :loading="loading"
                    hide-details
                    outlined
                    dense
                  />
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="closedTransfer">Close</v-btn>
                <v-btn
                  :disabled="
                    employeeUuid == '' || employeeUuid == null || loadingAction
                  "
                  color="primary"
                  :loading="loadingAction"
                  text
                  @click="transfer"
                  >Confirm</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-toolbar>

      <div class="d-flex align-center pa-3">
        {{/* Filter */}}
        <div class="col-sm-6">
          <v-select
            v-if="isAdmins"
            v-model="filterPa.employeeUuid"
            label="Filter for Employees"
            :items="workers"
            prepend-inner-icon="mdi-doctor"
            item-text="fullname"
            item-value="uuid"
            :loading="loading"
            hide-details
            clearable
            outlined
            dense
            @change="getPatients"
          />
        </div>
      </div>

      <v-divider />

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="patients"
        :options.sync="options"
        :loading="disableWhileLoading"
        :server-items-length="totalpatient"
        :items-per-page="10"
        item-key="uuid"
        show-select
        @select="selecteds"
        @click:row="showDetails"
      >
        <template v-slot:[`item.isMale`]="{ item }">
          {{ item.isMale ? $t("labelMale") : $t("labelFemale") }}
        </template>
        <not-records slot="no-data" />
      </v-data-table>
      <v-layout justify-center>
        <errors v-model="showErrors" class="mt-3" :message="errorMessage" />
      </v-layout>
    </v-card>
  </v-layout>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from "vue";
import NotRecords from "@/components/NotRecords.vue";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { getAPI } from "@/api/axios-base";
import Errors from "../PdfSign/Errors.vue";
import PatientFilter from "./PatientFilter.vue";
import Patient from "@/models/Patient";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "crm-transfer-patients",
  components: {
    NotRecords,
    Errors,
    // PatientFilter,
  },
  data() {
    return {
      isAdmins: false,
      options: {},
      filterPa: {
        employeeUuid: null,
        range: { limit: 100, offset: 0 },
      },
      singleSelect: false,
      signature: new Blob(),
      loadingAction: false,
      showErrors: false,
      doctorSelect: false,
      transferDialog: false,
      employeeUuid: "",
      patients: [],
      totalpatient: 0,
      ocupationId: "",
      doctorstotransfer: [],
      ocupations: [],
      errorMessage: "",
      loadingSendStep: false,
      headers: [
        { text: this.$t("Fullname"), value: "fullname", sortable: false },
        { text: this.$t("labelGender"), value: "isMale", sortable: false },
        { text: "Email", value: "email", align: "end", sortable: false },
        {
          text: this.$t("labelHomePhone"),
          value: "homePhone",
          align: "end",
          sortable: false,
        },
        // { text: "", value: "actions", sortable: false },
        // { text: "", value: "data-table-expand" },
      ],
      selected: [],
      contador: 0,
      currentPatient: {
        fullname: "",
        phone: "",
        email: "",
        uuid: "",
      },
      dialogEdit: false,
      dialogConfirmRemove: false,
      dialogDocument: false,
      dialogSignStep1: false,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["doctors", "agents", "loading"]),
    ...mapState("crmEmployeeModule", ["workers"]),
    ...mapGetters(["isSuper", "isAdmin", "getpatientN"]),
    disableWhileLoading() {
      return this.loading || this.loadingAction || this.loadingSendStep;
    },

    selecteds() {
      if (this.selected.length != 0) {
        return true;
      }
      return false;
    },
  },
  watch: {
    employeeUuid(val) {
      if (val != "" && val != null) {
        const worker = this.workers.filter((w) => w.uuid == val)[0];
        this.ocupationId = worker.ocupation.id;
      } else {
        this.ocupationId = "";
      }
    },
    contador(val) {
      if (val == this.selected.length) {
        notifySuccess(this.$t("medicForm.notificationPatientTransfer"));
        this.init();
        this.closedTransfer();
      }
    },
    options(val) {
      if (val != {}) {
        this.getPatients();
      }
    },
  },
  async mounted() {
    localStorage.setItem("filterfrompatients", true);
    if (this.isSuper || this.isAdmin) {
      this.isAdmins = true;
      this.init();
    }
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actRemovePatient",
      "actFilterPatient",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapMutations("crmMedicFormModule", ["mutSetPatients"]),
    showDetails(patient) {
      this.$router.push(`/patients/details/${patient.uuid}`);
    },
    async listOcupations() {
      this.ocupations = (await getAPI(`/sprocedures/listNom/Ocupation`)).data;
    },
    employeeSelect(valor) {
      this.doctorSelect = valor;
      this.selected = [];
    },
    getPatients() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      this.filterPa.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      if (
        this.filterPa.employeeUuid != null &&
        this.filterPa.employeeUuid != "" &&
        this.filterPa != undefined
      ) {
        getAPI
          .post("/patient/filterPatients", {
            employeeUuid: this.filterPa.employeeUuid,
            range: this.filterPa.range,
          })
          .then((res) => {
            this.patients = res.data.result;
            this.totalpatient = res.data.count;
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loadingAction = false;

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },

    async init() {
      await this.actGetEmployees();
      await this.listOcupations();
      this.mutSetPatients([]);

      this.selected = [];
      this.doctorstotransfer = this.workers;
      this.doctorSelect = false;
    },
    closedTransfer() {
      this.transferDialog = false;
      this.employeeUuid = "";
      this.ocupationId = "";
      this.loadingAction = false;
    },
    transfer() {
      const patientTransfer = this.selected.map((patient) => patient.uuid);
      this.contador = 0;
      this.loadingAction = true;
      patientTransfer.forEach((p) => {
        getAPI
          .post("/patient/createRelation", {
            patientUuid: p,
            employeeUuid: this.employeeUuid,
            ocupationId: this.ocupationId,
          })
          .then(() => {
            this.contador++;
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loadingAction = false;

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      });
    },
  },
});
</script>

<style lang="scss">
.patient-list {
  .v-data-table {
    tbody {
      tr {
        cursor: pointer !important;
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "Fullname": "Full name",
   
    
    "labelGender": "Gender",
    "labelHomePhone": "Phone",
    "labelEmergencyPhone": "Emergency phone"
  },
  "es": {
    "Fullname": "Nombre completo",
 
    "labelGender": "Género",
    "labelHomePhone": "Teléfono",
    "labelEmergencyPhone": "Teléfono de emergencias"
  }
}
</i18n>
